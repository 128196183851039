<template>
    <dashboard-layout>
        <div class="content-area__body">
            <section class="pipeline-section list-view">
                <pipeline-page-top-bar :job="job"></pipeline-page-top-bar>
                <pipeline-page-toggler :job="job"></pipeline-page-toggler>
                <board-view-candidate></board-view-candidate>
            </section>
        </div>
    </dashboard-layout>
</template>
<script>

import {EventBus} from "../../../extra/event-bus";
import {SWITCHED_TO_ANOTHER_ACCOUNT} from "../../../constants/events";
import {mapActions, mapState} from "vuex";
import {SWITCH_TO_ACCOUNT, UPDATE_PAGE_STATE} from "../../../constants/action-type";
import client from "../../../app/api/Client";

const DashboardLayout = () => import("../../../layouts/DashboardLayout");
const PipelinePageTopBar = () => import("../../../components/jobs/pipeline/PipelinePageTopBar");
const PipelinePageToggler = () => import("../../../components/jobs/pipeline/PipelinePageToggler");
const BoardViewCandidate = () => import("../../../components/jobs/pipeline/BoardViewCandidate");

export default {
    components: {
        DashboardLayout,
        PipelinePageTopBar,
        PipelinePageToggler,
        BoardViewCandidate,
    },
    data() {
        return {
            job: [],
        }
    },
    computed: {
        ...mapState(['company', 'user']),
    },
    methods: {
        ...mapActions([UPDATE_PAGE_STATE]),

        async getPipelineWithCandidate() {
            this.isContentLoading = true;
            try {

                let {data: {data: {pipelines, job, switchCompany, company_id, ai_score_show}}} = await client().get(`/job/${this.$route.params.jobId}/pipeline`);

                if (switchCompany) {
                    this.switchCompany(company_id);
                }

                this.job = job;
                this.UPDATE_PAGE_STATE({pipelines, ai_score_show});

            } catch (error) {
                this.$toast.error(this.$t(error.response.data.message));
                if (error.response.status === 480 || error.response.status === 490) {
                    await this.$router.push({name: 'jobs.published'});
                }
            }
            this.isContentLoading = false;
        },

        switchCompany(companyId) {
            if (companyId !== this.company.id) {
                let company = _.find(this.user.companies, {id: companyId});

                this[SWITCH_TO_ACCOUNT](company);

                EventBus.$emit(SWITCHED_TO_ANOTHER_ACCOUNT, 'company');
            }
        },

        setDefaultPageState() {
            this[UPDATE_PAGE_STATE]({
                pipelines: [],
                candidates: [],
                selectedApplicant:[],
                activeStage: null,
                showOnBoardingModal: false,
                showRemoteInterviewModal: false,
                dragApplicantId: null,
                dragPipelineId: null,
                moveToPipeline: null,
                dropCandidate: true,
                defaultBoard: false,
                prevPipelineId: null,
                oldDraggableIndex: null,
                sortBy: '',
                candidatePipelineOrders: [],
                ai_score_show: false,
            });
        }
    },
    async created() {
        this.setDefaultPageState();

        await this.getPipelineWithCandidate();
    }
}
</script>
